













































































































import Vue from "vue"
import { mapGetters } from "vuex";
import { v4 as uuidv4 } from "uuid";

import { getImageDimensions, getVideoDimensions } from "@/utils";
import { actionType, Highlight, ImageUpload } from "@/store/types";

export default Vue.extend({
  name: "HighlightForm",

  props: {
    highlight: { type: Object, default: null }
  },

  data() {
    return {
      actionType,
      select: [
        { text: "Sim", value: true },
        { text: "Não", value: false }
      ],
      actionTypes: [
        { text: "Link", value: "LINK" },
        { text: "Email", value: "EMAIL" },
        { text: "Pix", value: "PIX" },
        { text: "Unset", value: "UNSET" }
      ],
      payload: {
        _id: "",
        user_id: null,
        app_ids: [],
        active: true,
        description: "",
        archived: false,
        disable_comments: true,
        disable_share: true,
        disable_likes: true,
        is_paid_partnership: false,
        has_video: false,
        thumbnails: [],
        video_versions: [],
        action: {
          value: "",
          text: "Saiba Mais",
          type: actionType.Unset
        },
        created_at: new Date(),
        updated_at: new Date(),
      } as Highlight
    };
  },

  computed: {
    ...mapGetters({
      userId: "auth/getUid",
      apps: "mobileAppsModule/getApps",
    }),

    thumbnail(): string {
      const placeholder = "https://i.imgur.com/tV3k15O.jpg"
      return this.payload.thumbnails[0]?.url ?? placeholder
    },

    video(): string {
      const placeholder = require("../../../assets/video-placeholder.mp4")
      return this.payload.video_versions[0]?.url ?? placeholder
    }
  },

  mounted() {
    this.$store.dispatch("mobileAppsModule/fetch");

    if (this.highlight) {
      Object.assign(this.payload, this.highlight)
    }
  },

  methods: {
    save(): void {
      if (!this.validateForm()) return;

      this.payload.updated_at = new Date()
      this.payload.user_id = this.userId

      if (this.highlight) {
        this.$store.dispatch("highlightsModule/update", this.payload);
      } else {
        this.$store.dispatch("highlightsModule/create", this.payload);
      }

      this.$emit("close");
    },

    validateForm(): Boolean {
      // if (!this.payload.sku) {
      //   this.showError("Insira o SKU da oferta!");
      //   return false;
      // }
      return true;
    },

    isFloatNumber(value: any): Boolean {
      return isNaN(parseFloat(value));
    },

    isValidUrl(string: string): boolean {
      try {
        new URL(string);
      } catch (_) {
        return false;
      }

      return true;
    },

    showError(message: string): void {
      this.$store.dispatch("app/showCustomError", message);
    },

    getFile(file: File) {
      const isImage = file.type.includes("image");
      const isVideo = file.type.includes("video");

      if (!isImage && !isVideo) {
        return this.$store.dispatch("app/showCustomError", "Arquivo não Suportado!");
      }

      if (file.size > 5242880) {
        return this.$store.dispatch("app/showCustomError", "Arquivo maior que 5Mb!");
      }

      const folder = isImage ? "/highlights/images" : "/highlights/videos";

      const extension = file.name.split(".")[1];

      const reader = new FileReader();
      reader.onload = async ({ target }) => {
        const image: ImageUpload = {
          folder,
          filename: `${uuidv4()}.${extension}`,
          file: new Uint8Array(target?.result as ArrayBuffer),
          metadata: {
            contentType: file.type,
            size: file.size,
          }
        }

        const dimensions = isImage
            ? await getImageDimensions(file)
            : await getVideoDimensions(file)

        this.$store.dispatch("uploader/upload", image)
            .then(url => {
              const storedFile = {
                url,
                width: dimensions.width,
                height: dimensions.height
              }

              if(isImage) {
                this.payload.thumbnails = [storedFile]
              } else {
                this.payload.video_versions = [storedFile]
              }
            })
      };
      reader.readAsArrayBuffer(file);
    },
  }
})
