
































import Vue from "vue";
import { mapGetters } from "vuex";
import FullScreenDialog from "@/components/FullScreenDialog.vue";
import HighlightsForm from "./components/HighlightForm.vue"
import HighlightsTable from "./components/HighlightTable.vue"
import { Highlight } from "@/store/types";

export default Vue.extend({
  name: "HighlightsView",
  
  components: {
    FullScreenDialog,
    HighlightsForm,
    HighlightsTable
  },

  data: () => ({
    fullScreenDialog: false,
    highlight: null as unknown | Highlight,
  }),

  computed: {
    ...mapGetters({
      highlights: "highlightsModule/getHighlights",
      total: "highlightsModule/getTotal",
      loading: "highlightsModule/getLoading"
    })
  },

  created() {
    this.$store.dispatch("highlightsModule/fetch");
  },

  methods: {
    editCol(highlight: Highlight): void {
      this.highlight = highlight;
      this.openFullScreenDialog();
    },

    deleteCol(highlight: Highlight): void {
     this.$store.dispatch("highlightsModule/archive", { _id: highlight._id });
    },

    openFullScreenDialog(): void {
      this.fullScreenDialog = true;
    },

    fetchHighligs(payload: { page: number, pageSize: number }): void {
      this.$store.dispatch("highlightsModule/fetch", payload);
    },

    closeFullScreenDialog(): void {
      this.fullScreenDialog = false;
      this.highlight = null;
    },
  }
})
