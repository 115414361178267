var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stories-table"},[_c('ConfirmDialog',{attrs:{"title":"Arquivar o Destaque?","text":"Essa ação não pode ser revertida!","show":_vm.showDeleteDialog},on:{"close":function($event){_vm.showDeleteDialog = false},"confirm":_vm.onDelete}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"options":_vm.options,"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.pageSize,"loading":_vm.loading,"server-items-length":_vm.total},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.video",fn:function(ref){
var item = ref.item;
return [_c('video',{staticStyle:{"width":"50px","height":"88px","margin":"5px","object-fit":"cover"},attrs:{"src":_vm.extractVideo(item.video_versions)}})]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('small',[_c('b',[_vm._v(_vm._s(new Date(item.created_at).toLocaleString()))])])]}},{key:"item.apps",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm.buildAppNames(item.app_ids)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.active)?_c('v-btn',{staticClass:"m-small",attrs:{"icon":"","color":"green"}},[_vm._v(" Ativo ")]):_c('v-btn',{staticClass:"m-small",attrs:{"icon":"","color":"red"}},[_vm._v(" Inativo ")])]}},{key:"item.info",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"m-small",attrs:{"icon":"","color":item.disable_share ? 'red' : 'green'}},on),[_c('v-icon',[_vm._v("mdi-share")])],1)]}}],null,true)},[_c('span',[_vm._v("Compartilhamento "+_vm._s(item.disable_share ? 'Desativado' : 'Ativado'))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"m-small",attrs:{"icon":"","color":item.disable_likes ? 'red' : 'green'}},on),[_c('v-icon',[_vm._v("mdi-thumb-up")])],1)]}}],null,true)},[_c('span',[_vm._v("Curtidas "+_vm._s(item.disable_likes ? 'Desativadas' : 'Ativadas'))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"m-small",attrs:{"icon":"","color":item.is_paid_partnership ? 'green' : 'red'}},on),[_c('v-icon',[_vm._v("mdi-cash-multiple")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.is_paid_partnership ? 'É uma' : 'Não é uma')+" Parceria Paga")])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"blue","disabled":item.archived},on:{"click":function($event){return _vm.onEdit(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","color":"red","disabled":item.archived},on:{"click":function($event){return _vm.showDelDialog(item)}}},[_c('v-icon',[_vm._v("mdi-archive")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }